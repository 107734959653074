* {
    box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input:-webkit-autofill {
    border: none !important;
    transition: background-color 5000s ease-in-out 0s;
}

input:focus {
    outline: none;
}

img {
    width: 64px;
}

body {
    background-color: #ffffff;
    color: #000000;
    font-weight: medium;
    font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";;
    font-size: 16px;
    margin: 16px;
}

h1 {
    margin-top: 20px;
    font-size: 24px;
}

h2 {
    margin-top: 24px;
    font-size: 20px;
}

.main-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 850px;
    width: 90%;
}

.input-container {
    position: relative;
}

.input-container label {
    position: absolute;
    right: 15px;
    top: 13px;
    font-size: 16px;
    pointer-events: none;
    color: #000000;
    font-weight: bold;
}

input::-webkit-input-placeholder {
    font-weight: normal;
}

input {
    border-radius: 4px;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
    padding: 15px;
    width: 100%;
    background-color: #f6f6f6;
    border: 1px solid #e3e3f3;
    color: #000000;
}

.flex-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

button {
    display: block;
    font-size: 14px;
    transition: all 200ms ease-in-out;
    background-color: #a22631;
    color: #ffffff;
    border: 0;
    border-radius: 4px;
    height: 40px;
    margin-left: 10px;
    padding: 0 40px;
}

button:hover:enabled {
    background-color: #91222c;
}

button:enabled {
    cursor: pointer;
}

button:disabled {
    opacity: 0.4;
}